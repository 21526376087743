@import "projects/petszel-shared/src/assets/css/shared-styles.css";
/* petszel logo */
.petszel {
  width: 150px;
}

.back {
  color: black;
  font-size: 16px;
  font-weight: 600;
  padding: 50px;
}

.back:hover {
  color: #1c7690;
}

ul {
  list-style: none; /* Remove default list-style */
  padding-left: 0; /* Remove default padding */
  margin-left: 20px; /* Add left margin to indent the ul */
}

ul li {
  position: relative;
  padding-left: 30px; /* Add padding to make space for the bullet image */
  margin-bottom: 4px; /* Optional: add space between the list items */
}

ul li::before {
  content: "";
  position: absolute;
  left: 0;
  transform: translateY(0%); /* Adjust this value if necessary to fine-tune the alignment */
  background: url("../src/assets/images/triangle.png") no-repeat left center;
  width: 20px; /* Width of your custom bullet image */
  height: 20px; /* Height of your custom bullet image */
}

ul li p {
  display: block;
}

/* Styles for the table */
table {
  width: 100%; /* Or any other width you prefer */
  border-collapse: collapse; /* Removes spacing between cells */
}

/* Header style for th elements */
th {
  background-color: #f68522; /* Dark orange for the header */
  font-weight: bold;
  padding: 10px; /* Add some spacing */
  color: white;
  border: 1px solid white; /* Light border for cells */
}

/* Striped rows */
table tbody tr:nth-child(odd) {
  background: rgba(255, 211, 187, 0.5);
}

table tbody tr:nth-child(even) {
  background-color: white;
}

/* Styling for table cells */
table tbody tr td {
  padding: 10px; /* Add some spacing */
  border: 1px solid white; /* Light border for cells */
}

table tr th p {
  color: white;
  font-size: 20px;
}

.social-icon {
  font-size: 25px;
  color: white;
}

.petszel-col-c {
  color: white;
}

input.hs-button.primary.large {
  white-space: pre-wrap;
  background-color: purple!important;
}


/*
  FAQ Pages Nav Tabs and Accordion override styles
*/

.FAQ-content .nav-tabs,
.FAQ-content .nav-tabs .nav-link {
  border: none;
}

.FAQ-content .nav-tabs .nav-link {
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.FAQ-content .nav-tabs .nav-link.active {
  border-bottom: 3px solid #196981;
}

.FAQ-content .accordion-item {
  border: 0;
  border-radius: 10px;
  background-color: #FAFAFA;
  margin-bottom: 10pt;
  box-shadow: 0 4px 4px 0 #00000033;
}

.FAQ-content .accordion-button {
  border: 0;
  background-color: unset;
  box-shadow: none;
  border-radius: unset;
  color: black;
  font-weight: 500;
  font-size: 18px;
}

.FAQ-content .accordion-button p {
  margin: 0;
}

.FAQ-content .accordion-button:not(.collapsed)::after {
  background-image:  var(--bs-accordion-btn-icon);
}

.FAQ-content .accordion-body {
  padding-top: 0;
}

.FAQ-content__inner-html img {
  width: 20%;
  padding-bottom: 10pt;
}

.hero-section {
  width: 100%;
  overflow: hidden;
}

.bg-petszel {
  background-color: rgb(246, 133, 34, 0.1);
  background: linear-gradient(
    180deg,
    rgb(246, 133, 34, 0.25),
    rgb(250, 162, 84, 0.2),
    rgb(255, 191, 135, 0.15),
    rgb(255, 220, 189, 0.12),
    rgb(255, 255, 255, 0.08),
    rgb(209, 241, 255, 0.08),
    rgb(167, 228, 255, 0.12),
    rgb(100, 183, 220, 0.15),
    rgb(39, 142, 187, 0.2),
    rgb(39, 142, 187, 0.25)
  );
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  margin: 0;
}

.primaryBtn,
.primaryBtnSm {
  padding: 10px 20px 10px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.primaryBtn:hover,
.primaryBtnSm:hover {
  background-color: #71c0d6;
}

.primaryBtnSm {
  width: fit-content;
}

.h5 {
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.content-below-hero {
  padding: 2rem 1.5rem 1rem 1.5rem;
}

.content-title {
  font-weight: 700;
  font-size: 25px;
  line-height: 33px;
  margin-bottom: 10pt;
}

.cta-text {
  margin-top: 20px;
}

.cta-btn {
  margin-right: 10px;
}

.cta-text-btn {
  vertical-align: middle;
  margin: auto;
}

h5 {
  font-size: 20px;
  font-weight: bold;
}

#hubspotForm * {
  cursor: pointer;
  min-height: 879px;
}

.hero-logo-and-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-section h1 {
  margin-bottom: 15px;
}

.h5-normal {
  font-size: 20px;
  font-weight: bold;
}

.btn {
  padding: 10px 20px;
  white-space: nowrap;
  flex-grow: 1;
  text-decoration: none;
  color: white;
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0.5);
}

a {
  color: white;
}

.step-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.step-number-circle {
  background-color: #1c7690;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 50px;
  font-size: 40px;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 20px;
}

.content-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
}

.content-card img {
  max-width: 100px;
  height: auto;
}

.card-buttons .btn {
  margin-top: 10px;
}

.primaryBtn-card {
  border-radius: 14px;
  background: #1c7690;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: none;
  color: rgba(255, 255, 255, 1);
  height: 41px;
  padding: 10px 20px 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  font-size: 14px;
}

.card {
  padding: 20px 10px 10px 10px;
  border-radius: 30px;
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border: none;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
}

.card:hover {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
}
.card-body {
  padding-top: 20px;
}

.card-footer {
  display: flex;
}

.card-footer > div {
  flex: 1;
}

.logo-carousel-container {
  position: relative;
}

.logo-item img {
  width: 125px;
  height: 183px;
  object-fit: contain;
  filter: grayscale(100%);
}

.logo-carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: calc(100% - 60px);
  margin: 0 auto;
}

.logo-carousel::-webkit-scrollbar {
  display: none;
}

.logo-item {
  flex: 0 0 auto;
  padding: 10px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.carousel-control.left {
  left: 10px;
  text-shadow: 1px 0 currentColor, 0 1px currentColor, -1px 0 currentColor,
  0 -1px currentColor;
}

.carousel-control.right {
  right: 10px;
  text-shadow: 1px 0 currentColor, 0 1px currentColor, -1px 0 currentColor,
  0 -1px currentColor;
}

.quote-carousel-container {
  position: relative;
  overflow: hidden;
  padding: 0 30px;
}

.quote-carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 auto;
}

.quote-carousel::-webkit-scrollbar {
  display: none;
}

.quote-item {
  flex: 0 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.card-quote {
  display: flex;
  flex-direction: row;
  border: solid 1px #d9d9d9;
  border-radius: 10px;
  padding: 20px;
  min-height: 100%;
  max-width: 750px;
  overflow: hidden;
}

.card-quote-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.organization-logo {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.quote-logo {
  width: 160px;
  object-fit: contain;
  margin-top: 5px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  z-index: 999;
}

.blockquote {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.quote-text {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
}

.blockquote-footer-name {
  font-size: 18px;
  color: black;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.footer {
  font-size: 15px;
  font-style: normal;
}

.image-fade-container {
  position: relative;
  overflow: hidden;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-fade-container::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}

.img-fluid {
  display: block;
  width: 100%;
  height: auto;
}

.section-img {
  max-height: 400px;
  width: auto;
}
.indent {
  margin-left: 50px;
}

.content-container {
  display: flex;
  align-items: stretch;
  margin-bottom: 1rem;
}


.image-container {
  flex: 0 0 auto;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.responsive-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dog-laptop {
  width: 100%;
  height: auto;
  object-fit: cover;
  align-self: flex-end;
}



/**
  Media Queries Section
*/

@media (max-width: 767px) {

  .quote-carousel-container {
    padding: 0; /* Remove padding for the arrows */
  }
  .sign-up{
    display: none;
  }
  .quote-item {
    flex-direction: column-reverse;
  }

  .quote-item .col-md-8,
  .quote-item .col-md-4 {
    width: 100%;
  }
  .section-img {
    height: 300px;
  }
  .step-section-image {
    display: flex;
    justify-content: center;
  }
  .carousel-control {
    display: none; /* Hide the carousel controls */
  }

  .quote-carousel {
    flex-direction: column; /* Stack the quote items vertically */
    overflow-x: hidden; /* Remove horizontal scroll */
  }

  .quote-item {
    width: 100%; /* Full width for each quote item */
  }

  .card-quote {
    flex-direction: column; /* Stack image, text, and logo vertically */
    max-width: none; /* Remove max-width restriction */
    padding: 0;
  }

  .card-quote-body {
    flex-direction: column; /* Stack image, text, and logo vertically */
  }

  .image-section {
    order: -1 !important; /* Place the image section at the top */
    padding-left: 0; /* Reset padding */
  }

  .organization-logo {
    width: 100%; /* Full width of the container */
    height: 200px; /* Fixed height */
    object-fit: cover; /* Cover the area without stretching the image */
    filter: none; /* Remove grayscale filter */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  /* Adjust the blockquote and text styles as needed for small screens */
  .blockquote {
    margin: 0; /* Reset margin if necessary */
    padding: 20px;
  }

  .quote-text {
    font-size: 1em; /* Adjust font size for small screens */
  }

  .blockquote-footer-name,
  .footer {
    font-size: 0.875em; /* Adjust font size for small screens */
  }

  .image-fade-container {
    height: 200px;
  }
  .image-fade-container img.img-fluid {
    height: 200px; /* Set the fixed height for the image */
    width: 100%; /* Ensure the image is full width */
    object-fit: cover; /* Cover the area without stretching the image */
    margin: 0 auto; /* Center the image in case it's not full-width */
  }
  .image-fade-container::after {
    width: 100%; /* Full width for mobile view */
    height: 50%; /* Height of the fade effect */
    bottom: 0;
    top: auto; /* Reset top position */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    ); /* Gradient from bottom to top */
  }
  .h5-normal {
    font-size: 16px;
    font-weight: bold;
  }

  .logo-item img {
    width: 68px;
    height: 100px;
    object-fit: contain;
    filter: grayscale(100%);
  }

  .primaryBtn-card {
    font-size: 14px;
    padding: 8px 15px;
    width: 100%;
  }

  .card-footer > div:nth-of-type(2),
  .hide-on-small {
    display: none;
  }

  .card-body .card-text {
    display: none;
  }

  .social-icon {
    font-size: 15px;
  }
  .petszel-col-c {
    margin-bottom: 1.25rem;
  }
  ul li p {
    font-size: 14px;
  }
  h5.card-title {
    font-size: 16px;
  }
  .petszel-col-c {
    font-size: 14px;
  }
  /* Styles for the table */
  table {
    margin-bottom: 20px;
  }

  table tbody tr td p {
    font-size: 14px;
    margin: auto;
  }

  table tr th p {
    color: white;
    font-size: 16px;
    margin: auto;
  }

  .primaryBtnSm,
  .primaryBtn {
    font-size: 12px;
    padding: 8px 15px;
  }

  .primaryBtnSm {
    width: fit-content;
  }

  .FAQ-content__inner-html > img {
    width: 80%;
  }

  .cta-text {
    font-size: 16px;
  }

  .cta-text-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 687px) {
  .team {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .quote-carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 825px) {
  .team {
    justify-content: center;
  }
}

@media (max-width: 961px) {
  .image-container {
    width: auto; /* Set a fixed width */
    height: 200px; /* Set a fixed height to maintain a square */
    margin: auto;
  }

  .card-footer > div:nth-of-type(2),
  .hide-on-small {
    display: none;
  }
}

@media screen and (min-width: 450px) and (max-width: 1300px) {
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* This will take care of spacing between cards */
  }

  .card-container .custom-card {
    flex: 1 1 auto; /* Allow the card to grow and shrink */
    min-width: 300px; /* Minimum width of the card */
    max-width: calc(
      33.3333% - 20px
    ); /* Subtract the total margin from the width for 3 cards per row */
    margin: 10px; /* Ensures a gap of at least 10px */
    box-sizing: border-box; /* Include padding and borders in the element's total width and height */
  }
}
